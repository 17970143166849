import axios from '@axios'
import gql from 'graphql-tag'
import { newApolloClient } from '@/vue-apollo'
import router from '@/router'

export default {
  namespaced: true,
  state: {
    calendarOptions: [
      {
        color: 'primary',
        label: 'Modules',
      },
      {
        color: 'warning',
        label: 'Masterclasses',
      },
      {
        color: 'success',
        label: 'Workshops',
      },
      {
        color: 'info',
        label: 'Sessions',
      },
    ],
    selectedCalendars: ['Modules', 'Masterclasses', 'Workshops', 'Sessions'],
    data: [],
  },
  getters: {},
  mutations: {
    SET_DATA(state, val) {
      state.data = val
    },
    SET_SELECTED_EVENTS(state, val) {
      state.selectedCalendars = val
    },
  },
  actions: {
    fetchEvents(ctx, { calendars }) {
      return new Promise(resolve => {
        newApolloClient.query({
          query: gql`{  programs_basicinfo_by_pk(id: ${router.currentRoute.params.id}) {
              id
              programs_operationstables(where: {operations_type: {_ilike: "SubPhase"}}) {
                  id
                  title
                  description
                  location
                  status
                  begindate_timestamp
                  enddate_timestamp
                  programs_operationstables {
                      id
                      title
                      operations_type
                      begindate_timestamp
                      enddate_timestamp
                  }
              }
          }
            sessions_scheduletable(where: {program_id: {_eq: ${router.currentRoute.params.id}}}) {
              id
              title
              begin_time
              end_time
              status
              description
            }
          }`,
        })
          .then(result => {
            this.state.data = []
            result.data.programs_basicinfo_by_pk.programs_operationstables.forEach(module => {
              if (calendars.includes('Modules')) {
                this.state.data.push({
                  id: module.id,
                  url: '',
                  title: module.title,
                  start: new Date(module.begindate_timestamp),
                  end: new Date(module.enddate_timestamp),
                  priority: 1,
                  allDay: false,
                  extendedProps: {
                    description: module.description,
                    location: module.location,
                    status: module.status,
                    calendar: 'Modules',
                  },
                })
              }
              module.programs_operationstables.forEach(activity => {
                if (activity.operations_type === 'masterclass' && calendars.includes('Masterclasses')) {
                  this.state.data.push({
                    id: activity.id,
                    url: '',
                    title: activity.title,
                    start: new Date(activity.begindate_timestamp),
                    end: new Date(activity.enddate_timestamp),
                    priority: 2,
                    allDay: false,
                    extendedProps: {
                      description: activity.description,
                      location: activity.location,
                      status: activity.status,
                      calendar: 'Masterclasses',
                    },
                  })
                }
                if (activity.operations_type === 'workshop' && calendars.includes('Workshops')) {
                  this.state.data.push({
                    id: activity.id,
                    url: '',
                    title: activity.title,
                    start: new Date(activity.begindate_timestamp),
                    end: new Date(activity.enddate_timestamp),
                    priority: 3,
                    allDay: false,
                    extendedProps: {
                      description: activity.description,
                      location: activity.location,
                      status: activity.status,
                      calendar: 'Workshops',
                    },
                  })
                }
              })
            })
            result.data.sessions_scheduletable.forEach(session => {
              if (calendars.includes('Sessions')) {
                this.state.data.push({
                  id: session.id,
                  url: '',
                  title: session.title,
                  start: new Date(session.begin_time),
                  end: new Date(session.end_time),
                  priority: 4,
                  allDay: false,
                  extendedProps: {
                    description: session.description,
                    location: '',
                    status: session.status,
                    calendar: 'Sessions',
                  },
                })
              }
            })
            ctx.commit('SET_DATA', this.state.data)
            resolve(this.state)
          })
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/apps/calendar/events', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise(resolve => {
        newApolloClient.mutate({
          mutation: gql`mutation {
              update_programs_operationstable_by_pk(pk_columns: {id: ${event.id}}, _set: {title: "${event.title}", status: "${event.extendedProps.status}", begindate_timestamp: "${new Date(event.start).toISOString()}", enddate_timestamp: "${new Date(event.end).toISOString()}", location: "${event.extendedProps.location}", description: "${event.extendedProps.description}"}) {
                  id
                  title
                  begindate_timestamp
                  enddate_timestamp
                  status
                  location
                  description
              }
          }`,
        })
          .then(response => {
            resolve({
              event: {
                id: response.data.update_programs_operationstable_by_pk.id,
                title: response.data.update_programs_operationstable_by_pk.title,
                start: new Date(response.data.update_programs_operationstable_by_pk.begindate_timestamp),
                end: new Date(response.data.update_programs_operationstable_by_pk.enddate_timestamp),
                extendedProps: {
                  location: response.data.update_programs_operationstable_by_pk.location,
                  description: response.data.update_programs_operationstable_by_pk.description,
                  status: response.data.update_programs_operationstable_by_pk.status,
                },
              },
            })
          })
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/apps/calendar/events/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
