<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <section class="grid-view">
      <b-card
        v-for="(application, i) in applications"
        :key="i"
        :title="application.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <b-card-body>
          <b-card-text>
            <b-card-title class="mb-1">
              <b-badge
                variant="primary"
                size="lg"
                class="mr-25"
              >
                Round {{ application.round_number }}
              </b-badge>
              {{ application.title }}
              <small v-if="application.status !== 'enabled'">
                <b-badge
                  class="text-capitalize"
                  small
                > Applications
                  {{ application.status }}
                </b-badge>
              </small>
            </b-card-title>
            <hr>
            <p
              v-if="application.programs_applicantprogresstables[0]"
              class="h5"
            >Status:
              <b-badge
                pill
                :variant="application.programs_applicantprogresstables[0].status === 'Promoted' ? 'success' : ''"
                class="text-capitalize"
                size="lg"
                v-text="application.programs_applicantprogresstables[0].status"
              />
            </p>

            <p v-if="application.programs_applicantprogresstables[0]"><span class="h5">Recommendation: </span><br><em>{{
              application.programs_applicantprogresstables[0].recommendation
            }}</em></p>
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            v-if="application.review_status!=='enabled'"
            :disabled="!startup_id[0].organization_id_id"
            :to="{name: 'startup-view-evaluation', params: {pid: $route.params.id, apid: application.id, aid: startup_id[0].organization_id_id}}"
            class="btn btn-success btn-cart"
          >
            <span>Report</span>
          </b-link>
          <b-link
            :disabled="!startup_id[0].organization_id_id"
            :to="`/startup-portal/competition/application/${$route.params.id}/${application.id}/${startup_id[0].organization_id_id}`"
            class="btn btn-primary btn-cart"
          >
            <span>Open</span>
          </b-link>
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import {
  BBadge,
  BCard,
  BCardBody,
  BCardText,
  BCardTitle,
  BLink,
  BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BBadge,
    BOverlay,
    BLink,
  },
  data() {
    return {
      selectStartup: {
        id: null,
        current_application_form_id: null,
        organization_id_id: null,
      },
      startup_id: [],
      applicants: [],
      applications: [],
      userOrgs: getUserData()
        .associatedOrgDetails
        .map(el => el.organization_id)
        .filter(n => n),
    }
  },
  apollo: {
    applicants: {
      query() {
        return gql`
        {
         programs_applicantstable(where: {organization_id_id: {_in: [${this.userOrgs}]},program_id: {_eq: ${this.$route.params.id}}}) {
            current_application_form_id
            organization_id_id
            id
            users_organizationtable {
               title
            }
          }
        }`
      },
      update(data) {
        this.selectStartup = data.programs_applicantstable.find(e => e.id === Number.parseInt(this.$route.params.sid, 10))
        // eslint-disable-next-line no-return-assign,no-param-reassign
        data.programs_applicantstable.forEach(x => x.org = x.users_organizationtable.title)
        return data.programs_applicantstable
      },
    },
    applications: {
      query() {
        return gql`
        {
        programs_applicationformstable(order_by: {round_number: desc}, where: {program_id: {_eq: ${this.$route.params.id}}, status: {_neq: "draft"}, id: {_lte: ${this.$route.params.cid}}}) @include(if: ${!!this.$route.params.aid}) {
            id
            round_number
            title
            status
            review_status
            programs_applicantprogresstables(where: {applicant_id_id: {_eq: ${this.$route.params.aid} }}) {
              status
              recommendation
            }
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
    startup_id: {
      query() {
        return gql`
        {
          programs_applicantstable(where: {id: {_eq: ${this.$route.params.aid}}}) {
            organization_id_id
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
