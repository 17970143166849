<template>
  <div>

    <b-overlay
      :show="$apollo.loading || mutationLoading"
      rounded="sm"
    >
      <!-- search input -->
      <div class="custom-search d-flex justify-content-start">
        <b-form-group
          class="form-inline"
          label="Search"
          label-size="sm"
        >
          <b-form-input
            v-model="searchTerm"
            class="d-inline-block mr-1"
            placeholder="Search meeting"
            type="text"
          />
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :pagination-options="{
          enabled: true,
          perPage:pageLength
        }"
        :rows="rows"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span v-if="props.column.field === 'title'">
            {{ props.row.title }} <br> <small class="text-capitalize"><b-badge variant="light-primary">{{
              props.row.type
            }}</b-badge></small>

          </span>

          <span v-else-if="props.column.field === 'buttons'">
            <b-button
              :href="prependHttps(props.row.meeting_link)"
              class="mb-25 mr-25"
              size="sm"
              target="_blank"
              variant="outline-primary"
            >Join meeting</b-button>
            <b-button
              v-if="props.row.recording_link"
              :href="prependHttps(props.row.recording_link)"
              size="sm"
              target="blank"
              variant="outline-primary"
            >View recording</b-button>
          </span>
          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['3','5','10']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :per-page="pageLength"
                :total-rows="props.total"
                :value="1"
                class="mt-1 mb-0"
                first-number
                last-number
                next-class="next-item"
                prev-class="prev-item"
                @input="(value)=>props.pageChanged({currentPage:value})"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-overlay>
  </div>
</template>
<script>

import {
  BBadge, BButton, BFormGroup, BFormInput, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import gql from 'graphql-tag'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

export default {
  components: {
    BBadge,
    BFormInput,
    BFormGroup,
    BButton,
    VueGoodTable,
    BPagination,
    BFormSelect,
  },
  data() {
    return {
      title: '',
      type: '',
      beginTime: '',
      endTime: '',
      meetingLink: '',
      operationsId: null,
      status: '',
      mutationLoading: false,
      meetingTypeOptions: [
        'webinar', 'one-on-one', 'multi participant',
      ],
      searchTerm: '',
      selectedRow: 0,
      pageLength: 5,
      filter: {
        status: 'Current',
      },
      columns: [
        {
          label: 'Meeting',
          field: 'title',
        },
        {
          label: 'Time',
          width: '10rem',
          field(row) {
            if (!row.begin_time) return '-'
            return `${new Date(row.begin_time)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
                hour12: false,
              })} - ${new Date(row.end_time)
              .toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                timeZone: 'UTC',
                hour12: false,
              })}`
          },
        },
        {
          label: 'Purpose',
          field(row) {
            if (row.requesttable) return row.requesttable.title
            return row.programs_progressstagetable ? row.programs_progressstagetable.title : ''
          },
        },
        {
          label: 'Operation',
          field(row) {
            if (!row.requesttable && !row.programs_progressstagetable) {
              return 'General'
            }
            if (row.requesttable) {
              return row.requesttable.type
            }
            return 'Milestone'
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            placeholder: '-- No Filter --', // placeholder for filter input
            filterDropdownItems: [
              {
                value: 'General',
                text: 'General',
              },
              {
                value: 'Milestone',
                text: 'Milestone',
              },
              {
                value: 'Request',
                text: 'Request',
              },
              {
                value: 'mentor',
                text: 'Mentoring',
              },
              {
                value: 'partner',
                text: 'Partner Services',
              },
            ],
          },
        },
        {
          label: 'Status',
          field: 'status',
        },
        {
          label: 'Links',
          field: 'buttons',
          width: '11rem',
          sortable: false,
        },
      ],
      rows: [],
    }
  },
  methods: {

  },
  apollo: {
    rows: {
      query() {
        return gql`
        query startup_emeet{
          programs_scheduletable(order_by: {begin_time: desc}, where: {programs_meetparticipanttables: {users_organizationtable: {programs_applicantstables: {programs_startupparticipants: {id: {_eq: ${this.$route.params.aid} }}}}}}) {
            id
            title
            type
            begin_time
            end_time
            meeting_link
            recording_link
            status
            requesttable {
              title
              type
            }
            programs_progressstagetable {
              title
            }
            programs_meetparticipanttables {
              user_association_id
            }
          }
        }`
      },
      update: data => data.programs_scheduletable,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
