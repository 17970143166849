<template>
  <section id="card-navigation">
    <b-row>
      <!-- pill tabs inside card -->
      <b-col md="12">
        <b-overlay
          :show="$apollo.loading"
          spinner-variant="primary"
        >
          <b-card>
            <b-card-header
              class="justify-content-start p-0 pb-1 mb-1 border-bottom"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-secondary"
                @click="$router.back()"
              >
                <feather-icon
                  class="mr-25"
                  icon="ChevronLeftIcon"
                />
              </b-button>
              <h3 class="mb-0 ml-2">
                {{ program.title }}
              </h3>
            </b-card-header>

            <b-tabs
              v-model="activeTab"
              active
              nav-class="mb-3"
              pills
              class="pb-2"
              @input="updateHash"
            >
              <b-tab
                active
                class="text-left"
                href="Rounds"
                lazy
                title="Rounds"
              >
                <rounds-manager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Mentors"
                lazy
                title="Mentors"
              >
                <mentor-manager />
              </b-tab>
              <b-tab
                class="text-left"
                href="Modules"
                lazy
                title="Modules"
              >
                <module-manager />
              </b-tab>
              <b-tab
                href="Workshops"
                lazy
                title="Workshops"
              >
                <b-card
                  class="text-left"
                  title="Manage Workshop"
                >
                  <workshop-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="MasterClass"
                lazy
                title="Masterclass"
              >
                <b-card
                  class="text-left"
                  title="Manage Masterclass"
                >
                  <master-class-manager />
                </b-card>
              </b-tab>
              <b-tab
                class="text-left"
                href="E-Meet"
                lazy
                title="Sessions"
              >
                <b-card
                  class="text-left"
                  title="Meeting Manager"
                >
                  <emeet-manager />
                </b-card>
              </b-tab>
              <b-tab
                href="KnowledgeBase"
                lazy
                title="Library"
              >
                <b-card
                  class="text-left"
                  title="Library"
                >
                  <knowledge-base />
                </b-card>
              </b-tab>
              <b-tab
                title-item-class="calendarbtn"
                class="text-left"
                href="Activities"
                lazy
                title="Calendar"
              >
                <scheduler
                  v-if="!$apollo.loading"
                  :begin_date="program.begin_date"
                />
              </b-tab>
            </b-tabs>
          </b-card>
        </b-overlay>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BButton, BCard, BCardHeader, BCol, BOverlay, BRow, BTab, BTabs,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import gql from 'graphql-tag'
import RoundsManager from './competitions/CompetitionsRoundsManager.vue'
import MentorManager from './competitions/MentorManager.vue'
import ModuleManager from './competitions/EventsModuleManager.vue'
import WorkshopManager from './competitions/EventsWorkshopManager.vue'
import MasterClassManager from './competitions/EventsMasterClassManager.vue'
import EmeetManager from './competitions/EmeetManager.vue'
import KnowledgeBase from './competitions/KnowledgeBase.vue'
import Scheduler from './competitions/Scheduler.vue'

export default {
  components: {
    RoundsManager,
    MentorManager,
    ModuleManager,
    WorkshopManager,
    MasterClassManager,
    EmeetManager,
    KnowledgeBase,
    Scheduler,
    BRow,
    BCol,
    BTab,
    BTabs,
    BCard,
    BCardHeader,
    BOverlay,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activeTab: 0,
      tabs: ['#Rounds',
        '#Mentors',
        '#Modules',
        '#Workshops',
        '#MasterClass',
        '#Sessions',
        '#Library',
        '#Activities'],
      program: {},
    }
  },
  watch: {
    // Watch for changes in route, activate corresponding tab if hash is changed
    $route: {
      handler(to, from) {
        if (to.hash !== from.hash) {
          this.activeTab = this.tabs.findIndex(tab => tab === to.hash)
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activeTab = this.tabs.findIndex(tab => tab === this.$route.hash)
    })
  },
  methods: {
    updateHash(e) {
      this.$router.replace(this.$route.path + this.tabs[e])
    },
  },
  apollo: {
    program: {
      query() {
        return gql`
        {
        programs_basicinfo_by_pk(id: "${this.$route.params.id}"){
          title
        }
      }`
      },
      update: data => data.programs_basicinfo_by_pk,
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.calendarbtn{
  position: absolute;
  right: 20px;
  top: 20px;
  border: solid 1px #7367F0;
  border-radius: 8px;
}
</style>
